import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const LocationInfoTemplate = ({
  location,
  helmet,
}) => {
  return (
    <section className="section grid-container pre-footer-padding">
      {helmet || ''}
      <div className="grid-x grid-margin-x content">
        <div className="cell small-12 medium-8 medium-offset-2">
          <h1 className="page-header">{location.name}</h1>
          {location.address ?
            <div>
              <h3>Location</h3>
              <p>
                {location.room ? `Room: ${location.room} - ` : ''}
                {location.address}
              </p>
            </div> : ''}
          {location.buildingEntrance ?
            <div>
              <p>{location.buildingEntrance}</p>
            </div> : ''}

          {location.parking ?
            <div>
              <h4>Parking</h4>
              <p>{location.parking}</p>
            </div> : ''}

          {location.publicTransit ?
            <div>
              <h4>Public Transit</h4>
              <p>{location.publicTransit}</p>
            </div> : ''}

          {location.additionalNotes ?
            <div>
              <h4>Other Notes</h4>
              <p>{location.additionalNotes}</p>
            </div> : ''}

          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe title="location-map" width="100%" height="500" id="gmap_canvas" src={`https://maps.google.com/maps?q=${encodeURIComponent(location.search)}&t=&z=15&ie=UTF8&iwloc=&output=embed`} frameBorder='0' scrolling="no"></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

LocationInfoTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  contentComponent: PropTypes.func,
}

const LocationInfo = ({ data }) => {
  const { location } = data

  return (
    <Layout>
      <LocationInfoTemplate
        location={location}
        helmet={<Helmet title={`${location.name} | Location`} />}
      />
    </Layout>
  )
}

LocationInfo.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default LocationInfo

export const pageQuery = graphql`
  query LocationByID($id: String!) {
    location: fluxibleLocations(id: { eq: $id }) {
        id
        name
        address
        room
        latitude
        longitude
        search
        parking
        publicTransit
        buildingEntrance
        additionalNotes
    }
  }
`
